// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-casino-med-pay-n-play-js": () => import("./../../../src/pages/casino-med-pay-n-play.js" /* webpackChunkName: "component---src-pages-casino-med-pay-n-play-js" */),
  "component---src-pages-casino-med-snabba-uttag-js": () => import("./../../../src/pages/casino-med-snabba-uttag.js" /* webpackChunkName: "component---src-pages-casino-med-snabba-uttag-js" */),
  "component---src-pages-casino-med-swish-js": () => import("./../../../src/pages/casino-med-swish.js" /* webpackChunkName: "component---src-pages-casino-med-swish-js" */),
  "component---src-pages-erbjudande-js": () => import("./../../../src/pages/erbjudande.js" /* webpackChunkName: "component---src-pages-erbjudande-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nya-casino-js": () => import("./../../../src/pages/nya-casino.js" /* webpackChunkName: "component---src-pages-nya-casino-js" */),
  "component---src-pages-om-casinolabbet-js": () => import("./../../../src/pages/om-casinolabbet.js" /* webpackChunkName: "component---src-pages-om-casinolabbet-js" */),
  "component---src-pages-svenska-casino-js": () => import("./../../../src/pages/svenska-casino.js" /* webpackChunkName: "component---src-pages-svenska-casino-js" */),
  "component---src-templates-casino-js": () => import("./../../../src/templates/casino.js" /* webpackChunkName: "component---src-templates-casino-js" */)
}

